import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { strategyReducer } from 'ouisys-engine/strategy';
import { useDispatch as reduxUseDispatch } from 'react-redux';
import { createLogger } from 'redux-logger';
import includedReducers from './includedReducers';

const rootReducer = combineReducers({
  ...includedReducers,
  strategy: strategyReducer
});

const logger = createLogger();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['actionType'],
        ignoredPaths: ['payload.error']
      }
    }).concat([logger])
});

// export type RootState = ReturnType<typeof rootReducer>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => reduxUseDispatch<AppDispatch>();

export default store;
