import * as React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './Root';
if(typeof window === 'undefined'){
  window === global
}
export default Root;
const container = document.querySelector('#root');
const root = createRoot(container!);

if (!module.hot) root.render(<Root />);
