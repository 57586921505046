import React, { useEffect } from 'react';
import { Link } from 'ouisys-component-library';
import { injectIntl, FormattedMessage } from '../../localization/index';
import { tracker } from 'ouisys-engine/utilities/tracker';
import { SubmitUserDetailsSuccess } from 'ouisys-engine/creditCardFlow';
const CreditCardStep = ({ gateway_url }: SubmitUserDetailsSuccess) => {
  useEffect(() => {
    // if (typeof window != 'undefined') {
    //   tracker.advancedInFlow('tallyman.v1-credit-card', 'click', { gateway_url });
    //   // window.location.href = gatewayUrl;
    // }
  }, []);

  return <div></div>;
};

export default CreditCardStep;
