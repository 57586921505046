import React from 'react';
import { FormattedMessage } from '../../localization/index';
import './PreLander.scss';

const PreLander = ({ onClick }) => (
  <div className="PreLander" onClick={onClick}>
    <div className="PreLander_sample">
      <FormattedMessage
        id="pre-lander"
        defaultMessage="My PreLander {messageText}"
        values={{ messageText: 'messageText' }}
      />
    </div>
  </div>
);

export default PreLander;
