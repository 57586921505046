import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { searchToObject } from 'ouisys-engine/utilities/searchToObject';
import { tracker } from 'ouisys-engine/utilities/tracker';
import { setConfig } from 'ouisys-engine/utilities/getConfig';
import configJson from '../../config.json';
import { IStrategy } from 'ouisys-engine/strategy';
const config = configJson as IStrategy;
setConfig(configJson as IStrategy);
const RootContext = createContext(undefined);

// custom hook to easily use the context accross the app
export const useRootContext = () => useContext(RootContext);

export const RootProvider = ({ children }) => {
  const [operator, setOperator] = useState(null);
  const [msisdn, setMsisdn] = useState('');
  const [locale, setLocale] = useState('en');
  const urlParams = searchToObject(window);
  const d_locale = urlParams['locale'];
  const [skipPrelander, setPrelander] = useState(urlParams['skip-prelander'] === 'true' || true);
  const [isMoview, setIsMoView] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [shake, setShake] = useState(false);
  const defaultFlow = config?.strategyConfigs?.default?.flow || '';
  const [internationalNumber, setInternationalNumber] = useState(msisdn);
  const phoneInputRef = useRef(null);
  const Defaultlang = window.languageCode;

  const switchLang = (lang: string) => {
    setLocale(lang);
    if (typeof window !== 'undefined') {
      window.languageCode = lang;
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    }

    //Set locale based on url designer para
    if (d_locale) {
      switchLang(d_locale);
    }
  };

  const value = {
    operator,
    setOperator,
    msisdn,
    setMsisdn,
    locale,
    urlParams,
    switchLang,
    setPrelander,
    skipPrelander,
    config,
    setIsMoView,
    isMoview,
    setChecked,
    isChecked,
    defaultFlow,
    setInternationalNumber,
    internationalNumber,
    phoneInputRef,
    shake,
    setShake
  };

  useEffect(() => {
    if (typeof window != 'undefined') {
      window.languageCode = locale;
      document.getElementsByTagName('html')[0].setAttribute('lang', locale);
    }
    //Set locale based on url designer param
    if (d_locale) {
      setLocale(d_locale);
    }
    if (typeof window != 'undefined') {
      if (/^es\b/.test(window.navigator.language)) {
        setLocale('es');
        tracker.customEvent('Lang-Switcher', 'autodetect', 'browserLocale', 'es');
      }

      if (/^fr\b/.test(window.navigator.language)) {
        setLocale('fr');
        tracker.customEvent('Lang-Switcher', 'autodetect', 'browserLocale', 'fr');
      }

      if (/^it\b/.test(window.navigator.language)) {
        setLocale('it');
        tracker.customEvent('Lang-Switcher', 'autodetect', 'browserLocale', 'it');
      }

      if (/^en\b/.test(window.navigator.language)) {
        setLocale('en');
        tracker.customEvent('Lang-Switcher', 'autodetect', 'browserLocale', 'en');
      }
    }
  }, []);

  return <RootContext.Provider value={value}>{children}</RootContext.Provider>;
};
