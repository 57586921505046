
// Auto-generated file. Do not delete or modify.
import React from "react";

const includedFlows: { [key: string]: any } = {
  CreditCardFlow: require("../flows/CreditCardFlow").default,

};

export default includedFlows;
