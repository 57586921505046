import React from 'react';
import './Loader.scss';

const Loader = () => (
  <div className="Loader">
    <div className="Loader_spinner">
      {Array.from(Array(12)).map((item, index) => (
        <div key={`${index}-${item}`} />
      ))}
    </div>
  </div>
);

export default Loader;
