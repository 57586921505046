// adapted from is-expiry-invalid from react-credit-card-input

const EXPIRY_DATE_REGEX = /^(\d{2})\/(\d{4}|\d{2})$/;
const MONTH_REGEX = /(0[1-9]|1[0-2])/;

export default function (expiryDate) {
  const splitDate = expiryDate.split('/');
  if (!EXPIRY_DATE_REGEX.test(expiryDate)) {
    return true;
  }

  const expiryMonth = splitDate[0];
  if (!MONTH_REGEX.test(expiryMonth)) {
    return true;
  }

  const expiryYear = splitDate[1];
  let date = new Date();
  let currentYear = date.getFullYear();
  let currentMonth = date.getMonth() + 1;
  currentYear = parseInt(expiryYear.length === 4 ? currentYear : currentYear.toString().substr(-2), 10);
  if (currentYear > parseInt(expiryYear, 10)) {
    return true;
  }

  if (parseInt(expiryYear, 10) - currentYear > 20) {
    return true;
  }

  if (parseInt(expiryYear, 10) === currentYear && parseInt(expiryMonth, 10) < currentMonth) {
    return true;
  }

  return false;
}
