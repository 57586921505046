import React from 'react';
import {
  IUserDetails,
  SubmitUserDetailsSuccess,
  creditCardFlowMatch,
  submitUserDetailsAction,
  useCreditCardFlowState
} from 'ouisys-engine/creditCardFlow';
import { useDispatch } from '../../__doNotModify/store';
import { RDS, useStrategyState } from 'ouisys-engine/strategy';
import UserDetailsEntryStep from '../../components/UserDetailsEntryStep';
import { useSelector } from 'react-redux';
import CreditCardStep from '../../components/CreditCardStep';

const CreditCardFlow = () => {
  const creditCardFlowState = useCreditCardFlowState(useSelector);
  const strategyState = useStrategyState(useSelector);
  const dispatch = useDispatch();
  const onEnd = (userDetails: IUserDetails) => {
    dispatch(submitUserDetailsAction({ window, userDetails }));
  };
  return (
    <div>
      <div id="divFocus">
        {creditCardFlowMatch({
          userDetailsEntry: (rds) => (
            <div>
              {RDS.WhenSuccess<SubmitUserDetailsSuccess, JSX.Element>(
                <UserDetailsEntryStep rds={rds} onEnd={onEnd} />,
                (data) => <CreditCardStep {...data} />
              )(rds)}
            </div>
          )
        })(creditCardFlowState)}
      </div>
    </div>
  );
};

export default CreditCardFlow;
