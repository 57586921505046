import * as React from 'react';
import { FormattedMessage } from '../../localization/index';

const iconEmail = require('../../assets/imgs/icon-email.png');
const iconTel = require('../../assets/imgs/icon-tel.png');
const ccMaster = require('../../assets/imgs/cc-master.png');
const ccVisa = require('../../assets/imgs/cc-visa.png');

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <h1>
          <FormattedMessage id="footer-h1" defaultMessage="Experience Entertainu on Any Device"></FormattedMessage>
        </h1>
        <div className="logo-wrap">
          <svg className="logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285.62 44.26">
            <path
              d="m59.31,30.54c-1.13-1.09-1.69-2.67-1.69-4.73v-7.7c0-2.06.56-3.64,1.69-4.73,1.13-1.09,2.99-1.63,5.57-1.63h6.83v3.21h-6.83c-1.07,0-1.86.25-2.38.76-.52.51-.77,1.3-.77,2.39v2.25h8.7v3.21h-8.7v2.25c0,1.07.26,1.86.77,2.38.51.52,1.31.77,2.38.77h6.89v3.21h-6.89c-2.59,0-4.45-.54-5.57-1.63Z"
              fill="#fbf8f0"
              stroke-width="0"
            />
            <path
              d="m92.08,11.75v15.61c0,1.58-.32,2.77-.96,3.59s-1.67,1.23-3.09,1.23h-2.89l-6.01-13.92v13.92h-3.85V11.75h4.64l7.41,16.93h.06c.33,0,.55-.14.67-.42.12-.28.18-.79.18-1.53v-14.97h3.85Z"
              fill="#fbf8f0"
              stroke-width="0"
            />
            <path d="m100.96,14.96h-6.16v-3.21h16.43v3.21h-6.16v17.22h-4.12V14.96Z" fill="#fbf8f0" stroke-width="0" />
            <path
              d="m114.47,30.54c-1.13-1.09-1.69-2.67-1.69-4.73v-7.7c0-2.06.56-3.64,1.69-4.73,1.13-1.09,2.99-1.63,5.57-1.63h6.83v3.21h-6.83c-1.07,0-1.86.25-2.38.76-.52.51-.77,1.3-.77,2.39v2.25h8.7v3.21h-8.7v2.25c0,1.07.26,1.86.77,2.38.51.52,1.31.77,2.38.77h6.89v3.21h-6.89c-2.59,0-4.45-.54-5.57-1.63Z"
              fill="#fbf8f0"
              stroke-width="0"
            />
            <path
              d="m146.99,32.18h-4.35l-1.34-4.52c-.27-.93-.54-1.66-.82-2.17-.27-.51-.64-.91-1.11-1.18-.47-.27-1.09-.41-1.87-.41h-2.95v8.29h-4.12V11.75h8.26c2.49,0,4.33.49,5.52,1.47s1.78,2.51,1.78,4.6c0,1.34-.36,2.42-1.07,3.24-.71.82-1.69,1.34-2.93,1.58.99.33,1.74.9,2.25,1.72.51.82.97,1.93,1.4,3.33l1.34,4.5Zm-5.97-12.21c.57-.48.86-1.19.86-2.15s-.29-1.67-.86-2.15c-.57-.48-1.31-.72-2.2-.72h-4.26v5.72h4.26c.89,0,1.63-.24,2.2-.72Z"
              fill="#fbf8f0"
              stroke-width="0"
            />
            <path d="m154.31,14.96h-6.16v-3.21h16.43v3.21h-6.16v17.22h-4.12V14.96Z" fill="#fbf8f0" stroke-width="0" />
            <path
              d="m176.32,27.42h-8l-1.37,4.76h-4.12l4.99-16.29c.35-1.17.72-2.07,1.11-2.71.39-.64.85-1.1,1.39-1.37.53-.27,1.2-.41,2-.41s1.46.14,1.98.41.99.73,1.39,1.37c.4.64.77,1.55,1.12,2.71l4.99,16.29h-4.12l-1.37-4.76Zm-.9-3.06l-2.45-8.41c-.14-.51-.35-.76-.64-.76s-.51.25-.64.76l-2.45,8.41h6.19Z"
              fill="#fbf8f0"
              stroke-width="0"
            />
            <path d="m184.87,11.75h4.12v20.43h-4.12V11.75Z" fill="#fbf8f0" stroke-width="0" />
            <path
              d="m210.76,11.75v15.61c0,1.58-.32,2.77-.96,3.59-.64.82-1.67,1.23-3.09,1.23h-2.89l-6.01-13.92v13.92h-3.85V11.75h4.64l7.41,16.93h.06c.33,0,.55-.14.67-.42.12-.28.17-.79.17-1.53v-14.97h3.85Z"
              fill="#fbf8f0"
              stroke-width="0"
            />
            <path
              d="m216.68,30.85c-1.36-1.14-2.04-2.72-2.04-4.74v-14.36h4.12v14.36c0,2.16,1.32,3.24,3.97,3.24s3.97-1.08,3.97-3.24v-14.36h4.12v14.36c0,2.02-.68,3.6-2.04,4.74-1.36,1.14-3.38,1.71-6.04,1.71s-4.68-.57-6.04-1.71Z"
              fill="#fbf8f0"
              stroke-width="0"
            />
            <path
              d="m24.31,18.97c.1.06.2.11.3.17.08.25.28.31.51.3.04,0,.06,0,.08.04.14.32.43.39.74.43.04,0,.07,0,.08.04.08.26.28.31.51.3.1.06.2.11.3.17.02.21.23.24.35.36.91.87.75,2.32-.32,2.94-2.54,1.46-5.07,2.92-7.6,4.38-1.41.82-2.59.37-3.04-1.16,0-3.04,0-6.07,0-9.11,0-.15.03-.31.05-.46.15-.04.2-.11.08-.24.18-.62.6-1.01,1.19-1.23.17.07.33.1.44-.1.15-.06.29-.06.44,0,.47.22.93.49,1.45.6l.31.17c.08.25.27.31.51.3.04,0,.07,0,.08.04.14.31.43.39.73.43.04,0,.07,0,.08.05.08.26.27.31.51.3.1.06.2.12.31.17.08.25.27.31.51.3.04,0,.06,0,.08.04.14.32.43.39.73.43.04,0,.07,0,.08.04.08.26.28.31.51.3Z"
              fill="#a70041"
              stroke-width="0"
            />
            <path
              d="m12.81,44.19c-2.16-.04-4.32.23-6.45-.09-3.49-.52-6.12-3.49-6.34-7.01-.01-.24-.02-.48-.02-.72C0,26.85,0,17.32,0,7.79,0,4.36,1.97,1.55,5.12.42,5.99.11,6.89,0,7.8,0c9.54,0,19.09-.01,28.63,0,4.47,0,7.79,3.32,7.8,7.79.02,9.54.01,19.09,0,28.63,0,4.01-2.66,7.09-6.62,7.72-.32.05-.65.06-.97.06-5.8,0-11.6.01-17.4,0-2.54,0-4.63-1.65-5.18-4.01-.78-3.35,1.64-6.45,5.1-6.47,4.31-.03,8.62,0,12.93,0,2.06,0,3.61-1.52,3.61-3.59,0-5.32,0-10.64,0-15.96,0-2.15-1.49-3.67-3.64-3.68-6.62-.02-13.24-.02-19.86,0-2.33,0-3.76,1.5-3.76,3.82,0,6.64,0,13.27,0,19.91,0,1.37.03,2.74.25,4.09.49,3.15,1.35,4.35,4.12,5.86Z"
              fill="#a70041"
              stroke-width="0"
            />
          </svg>
        </div>
        <div className="footer-wrap">
          <div className="footer-left">
            <div className="customer-care">
              <h3>
                <FormattedMessage id="customer-care" defaultMessage="Customer Care"></FormattedMessage>
              </h3>
              <div className="list-wrap">
                <div className="each">
                  <div className="icon">
                    <img src={iconTel} />
                  </div>
                  <div className="details">
                    <a href="tel:0805985498">
                      0805 98 5498 (<FormattedMessage id="france" defaultMessage="France"></FormattedMessage>)
                    </a>
                  </div>
                </div>
                <div className="each">
                  <div className="icon">
                    <img src={iconTel} />
                  </div>
                  <div className="details">
                    <a href="tel:900751051">
                      900 751 051 (<FormattedMessage id="span" defaultMessage="Spain"></FormattedMessage>)
                    </a>
                  </div>
                </div>
                <div className="each">
                  <div className="icon">
                    <img src={iconEmail} />
                  </div>
                  <div className="details">
                    <a href="mailto:help@entertainu.tv">help@entertainu.online</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="company-info">
              <p>
                <strong className="text-gr">AMB GLOBAL DIGITAL SOLUTIONS LTD</strong>
                <br />
                1 Maiou 3, 2610 Agios Epifanios Oreinis, Cyprus
                <br />
                <a href="https://www.ambglobaldigital.com" target="_blank">
                  www.ambglobaldigital.com
                </a>
              </p>
            </div>
            <div className="copyright">
              {/* <script>document.write(new Date().getFullYear());</script> © Entertainu <FormattedMessage id="all-rights-reserved" defaultMessage="All Rights Reserved"></FormattedMessage> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="quick-links">
            <a className="each" href="https://portal.entertainu.tv/privacy-policy" target="_blank">
              <FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.entertainu.tv/refund-policy" target="_blank">
              <FormattedMessage id="refund-policy" defaultMessage="Refund Policy"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.entertainu.tv/terms-and-conditions" target="_blank">
              <FormattedMessage id="terms-conditions" defaultMessage="Terms & Conditions"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.entertainu.tv/faq" target="_blank">
              <FormattedMessage id="faq" defaultMessage="FAQ"></FormattedMessage>
            </a>
          </div>
          <div className="credit-card-icon">
            <img src={ccMaster} />
            <img src={ccVisa} />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
