import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { RDS } from 'ouisys-engine/strategy';
import { IUserDetails, SubmitUserDetailsFailure } from 'ouisys-engine/creditCardFlow';
import { Button, Input, Link, Select, SelectType, defineMessages } from 'ouisys-component-library';
import { FormattedMessage, injectIntl } from '../../localization';
import { tracker } from 'ouisys-engine/utilities/tracker';
import { InputType } from 'ouisys-component-library';
import CreditCardInput from 'react-credit-card-input';
import './UserDetailsEntryStep.scss';
import PricePointContent from '../PricePoint';

import payment from 'payment';
import isExpiryInvalid from './isExpiryInvalid.js';
import Footer from '../Footer';

// Custom hook for form state management
const useFormData = (initialState) => {
  const [formData, setFormData] = useState(initialState);

  const validateField = (event) => {
    const { name, value } = event.target;
    const valid = value.trim().length > 0;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value, valid }
    }));
  };

  return [formData, validateField];
};

// Define messages outside the component if they don't depend on props or state
const defaultMessages = {
  firstname: {
    id: 'firstname',
    defaultMessage: 'First Name'
  },
  lastname: {
    id: 'lastname',
    defaultMessage: 'Last Name'
  },
  email: {
    id: 'email',
    defaultMessage: 'Email'
  },
  address: {
    id: 'address',
    defaultMessage: 'Address'
  },
  postcode: {
    id: 'postcode',
    defaultMessage: 'Postcode'
  },
  city: {
    id: 'city',
    defaultMessage: 'City'
  },
  country: {
    id: 'country_code',
    defaultMessage: 'Country'
  },
  rockmanid: {
    id: 'rockman_id',
    defaultMessage: 'Rockman ID'
  },
  serviceid: {
    id: 'service_id',
    defaultMessage: 'Rockman ID'
  },
  required: {
    id: 'required',
    defaultMessage: 'is required'
  },
  cc_number: {
    id: 'cardNumber',
    defaultMessage: 'Credit Card Number'
  },
  cc_holder: {
    id: 'cardHolder',
    defaultMessage: ''
  },
  expirationYear: {
    id: 'expYear',
    defaultMessage: 'Expiry Year'
  },
  expirationMonths: {
    id: 'expMonth',
    defaultMessage: 'Expiry Month'
  },
  cvv: {
    id: 'cvv',
    defaultMessage: 'CVV Code'
  },
  invalid: {
    id: 'invalid',
    defaultMessage: 'is invalid'
  },
  expDate: {
    id: 'expDate',
    defaultMessage: 'Expiry Date'
  },
  expDatePlaceholder: {
    id: 'expDatePlaceholder',
    defaultMessage: 'MM/YY'
  },
  cvcPlaceholder: {
    id: 'cvcPlaceholder',
    defaultMessage: 'CVC'
  }
};

const UserDetailsEntryStep = ({ rds, onEnd, intl }) => {
  const vod = require('../../assets/imgs/vod-devices.png');
  const cvvIcon = require('../../assets/imgs/cvv-img.png');
  const { visitor } = window.pac_analytics;

  // email validation
  function isEmailValid(email) {
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  }

  // postcode validation
  function isPostCodeValid(postalCode) {
    var regex = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/;
    return regex.test(postalCode);
  }

  // validation
  const [validFirstname, setValidFirstname] = useState(true);
  const [validLastname, setValidLastname] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validAddress, setValidAddress] = useState(true);
  const [validPostcode, setValidPostcode] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [validCountry, setValidCountry] = useState(true);
  const [validExpirationYear, setValidExpirationYear] = useState(true);
  const [validExpirationMonths, setValidExpirationMonths] = useState(true);
  const [validCvv, setValidCvv] = useState(true);
  const [validCcnumber, setValidCc_number] = useState(true);
  const [validCcholder, setValidCcHolder] = useState(true);

  // fields and values
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [pasted, setPasted] = useState(false);
  const [lastEmailLength, setLastEmailLength] = useState(0);
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  // Get the country param and set it as default on country input
  const queryParameters = new URLSearchParams(window.location.search);
  const initialCountry = (queryParameters.get('d_country') || '').toUpperCase();
  const [country, setCountry] = useState(initialCountry);
  const [cc_number, setCc_number] = useState('');
  const [cc_holder, setCc_holder] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [expirationMonths, setExpirationMonths] = useState('');
  const [cvv, setCvv] = useState('');

  // 1 = UserDetails, 2 = CreditCardDetails
  const [step, setStep] = React.useState(1);
  const proceedToNextStep = () => {
    if (!firstname.length) {
      // firstname
      setValidFirstname(false);
    } else if (!lastname.length) {
      // lastname
      setValidLastname(false);
    } else if (!isEmailValid(email)) {
      // email
      setValidEmail(false);
    } else if (!address) {
      // address
      setValidAddress(false);
    } else if (!postcode) {
      // postcode
      setValidPostcode(false);
    } else if (!city) {
      // city
      setValidCity(false);
      // } else if (!country) {
      //   // country
      //   setValidCountry(false);
    } else {
      tracker.advancedInPreFlow('user-details-submission-success', { email });
      setStep(2);
    }
  };

  /*
    we can simplify this into one function with one useState
    let's refactor this part once the page is live - genesis

    const [formFields, setFormFields] = useState<any>({
      firstname: {
        value: '',
        valid: false
      },
      lastname: {
        value: '',
        valid: false
      },
      ...
    });
  */

  const handleFormEvents = (subscriptionState, input, interactionState, ev, value = '') => {
    if (ev.type === 'blur') {
      //                     /category                   // action                      // label         //args
      tracker.customEvent(`${subscriptionState}-state`, `${input}-${interactionState}`, `${input}-input`, value);
    }
    if (ev.type === 'click') {
      tracker.customEvent(`${subscriptionState}-state`, `${input + '-entry-started'}`, `${input}-input`, value);
    }
  };

  const validateFirstname = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setFirstname(value);
    setValidFirstname(valid);
    return valid;
  };
  const validateLastname = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setLastname(value);
    setValidLastname(valid);
    return valid;
  };
  const handleEmailPaste = (ev) => {
    setTimeout(() => {
      tracker.customEvent('USER-DETAILS-ENTRY-State', 'copypaste', 'EmailPasted', {
        msisdn: ev.target.value
      });
    }, 0);
    setPasted(true);
  };
  const validateEmail = (ev) => {
    ev.persist();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const value = ev.target.value;
    const valid = emailRegex.test(value);
    setEmail(value);
    setValidEmail(valid);
    setLastEmailLength(value.length);

    // Significant length change could indicate autofill, and we check that it wasn't a paste action
    if (!pasted && value.includes('@') && value.length - lastEmailLength > 1) {
      tracker.customEvent('USER-DETAILS-ENTRY-State', 'autofill', 'EmailAutoFill', {
        email: value
      });
    }

    // Update the length for the next change event
    setLastEmailLength(value.length);

    // Reset the pasted flag if it was set
    if (pasted) {
      setPasted(false);
    }

    handleFormEvents('user-details-entry', 'email', `${valid ? 'valid' : 'invalid'}`, ev);

    return valid;
  };
  const validateAddress = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setAddress(value);
    setValidAddress(valid);
    return valid;
  };
  const validatePostcode = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setPostcode(value);
    setValidPostcode(valid);
    return valid;
  };
  const validateCity = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCity(value);
    setValidCity(valid);
    return valid;
  };

  const validateCountry = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCountry(value);
    setValidCountry(valid);
    handleFormEvents('cc-form', 'country', `${valid ? 'valid' : 'invalid'}`, ev, value);
    return valid;
  };
  const validateCcnumber = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length >= 16;
    setCc_number(value);
    setValidCc_number(valid);
    return valid;
  };
  const validateCcholder = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCc_holder(value);
    setValidCcHolder(valid);
    handleFormEvents('cc-form', 'cc-name', `${valid ? 'valid' : 'invalid'}`, ev);
    return valid;
  };
  const handleCardExpiryInput = (ev) => {
    const monthYearString = ev.target.value;
    const [month, year] = monthYearString.split('/');
    setExpirationMonths(month?.trim());
    setValidExpirationMonths(true);
    const yearDigits = year?.trim();
    if (yearDigits) {
      const year = `20${yearDigits}`;
      // setting max year ahead to 20 years based on: https://stackoverflow.com/questions/2500588/maximum-year-in-expiry-date-of-credit-card
      if (+year - new Date().getFullYear() <= 20) {
        setExpirationYear(year);
        setValidExpirationYear(true);
      } else {
        setValidExpirationYear(false);
      }
    }
  };
  // const validateExpirationYear = (ev) => {
  //   ev.persist();
  //   const value = ev.target.value;
  //   const valid = value < 3000;
  //   setExpirationYear(value);
  //   setValidExpirationYear(valid);
  //   return valid;
  // };
  // const validateExpirationMonths = (ev) => {
  //   ev.persist();
  //   const value = ev.target.value;
  //   const valid = value < 13;
  //   setExpirationMonths(value);
  //   setValidExpirationMonths(valid);
  //   return valid;
  // };
  // const validateCvv = (ev) => {
  //   ev.persist();
  //   const value = ev.target.value;
  //   const valid = value.length > 0;
  //   setCvv(value);
  //   setValidCvv(valid);
  //   return valid;
  // };

  return (
    <div className="">
      <form
        autoComplete="on"
        onSubmit={(ev) => {
          ev.preventDefault();
          // setting the url param to complete the LC2 slug
          history.pushState(null, null, '?d_country=es');
          onEnd({
            first_name: firstname,
            last_name: lastname,
            email,
            address,
            postcode,
            city,
            country_code: country,
            cc_number,
            cc_holder,
            cc_exp_month: expirationMonths,
            cc_exp_year: expirationYear,
            cvv
          });
        }}
      >
        <section className="userDetails">
          <div className="">
            <div className="top">
              <h2>
                <FormattedMessage id="registerTitle" defaultMessage="Finish your Registration"></FormattedMessage>
              </h2>
              <p>
                <FormattedMessage
                  id="registerIntro"
                  defaultMessage="The subscription starts now for just €0.99 / 3 days trial. The subscription will be automatically prolonged for €49.99 / monthly after the first period"
                />
              </p>
            </div>
            <div className="bottom">
              <h4 className="desktop">
                <FormattedMessage id="userDetailsTitle2" defaultMessage="Create your account" />
              </h4>
              <div className="form-group hide">
                <label className="form-label">
                  <FormattedMessage id="firstname" defaultMessage="First Name" />
                </label>
                <Input
                  id={defaultMessages.firstname.id}
                  value={firstname}
                  onBlur={validateFirstname}
                  onChange={validateFirstname}
                  required
                  // label="Name"
                  placeholder=""
                  className="form-control"
                  error={
                    !validFirstname &&
                    intl.formatMessage(defaultMessages.firstname) + ' ' + intl.formatMessage(defaultMessages.required)
                  }
                  type={InputType.Text}
                  autoComplete="cc-given-name"
                />
              </div>
              <div className="form-group hide">
                <label className="form-label">
                  <FormattedMessage id="lastname" defaultMessage="Last Name" />
                </label>
                <Input
                  id={defaultMessages.lastname.id}
                  value={lastname}
                  onBlur={validateLastname}
                  onChange={validateLastname}
                  required
                  // label="Name"
                  // placeholder={intl.formatMessage(defaultMessages.lastname)}
                  className="form-control"
                  error={
                    !validLastname &&
                    intl.formatMessage(defaultMessages.lastname) + ' ' + intl.formatMessage(defaultMessages.required)
                  }
                  type={InputType.Text}
                  autoComplete="cc-family-name"
                />
              </div>
              <div className="form-group">
                <label className="form-label email">
                  <FormattedMessage id="email" defaultMessage="Email" />
                </label>
                <Input
                  id={defaultMessages.email.id}
                  value={email}
                  onClick={(ev) => {
                    handleFormEvents('user-details-entry', 'email', `entry-started`, ev);
                  }}
                  onBlur={validateEmail}
                  onChange={validateEmail}
                  onPaste={handleEmailPaste}
                  required
                  // label="Email"
                  // placeholder={intl.formatMessage(defaultMessages.email)}
                  className="form-control"
                  error={
                    !validEmail &&
                    intl.formatMessage(defaultMessages.email) + ' ' + intl.formatMessage(defaultMessages.required)
                  }
                  type={InputType.Email}
                  autoComplete="email"
                />
              </div>
              <div className="form-group hide">
                <label className="form-label">
                  <FormattedMessage id="address" defaultMessage="Address" />
                </label>
                <Input
                  id={defaultMessages.address.id}
                  value={address}
                  onBlur={validateAddress}
                  onChange={validateAddress}
                  required
                  // label="Address"
                  // placeholder={intl.formatMessage(defaultMessages.address)}
                  className="form-control"
                  error={
                    !validAddress &&
                    intl.formatMessage(defaultMessages.address) + ' ' + intl.formatMessage(defaultMessages.required)
                  }
                  type={InputType.Text}
                  autoComplete="billing street-address"
                />
              </div>
              <div className="form-group hide">
                <label className="form-label">
                  <FormattedMessage id="postcode" defaultMessage="Postcode" />
                </label>
                <Input
                  id={defaultMessages.postcode.id}
                  value={postcode}
                  onBlur={validatePostcode}
                  onChange={validatePostcode}
                  required
                  // label="Postcode"
                  // placeholder={intl.formatMessage(defaultMessages.postcode)}
                  className="form-control"
                  error={
                    !validPostcode &&
                    intl.formatMessage(defaultMessages.postcode) + ' ' + intl.formatMessage(defaultMessages.required)
                  }
                  type={InputType.Text}
                  autoComplete="billing postal-code"
                />
              </div>
              <div className="form-group hide">
                <label className="form-label">
                  <FormattedMessage id="city" defaultMessage="City" />
                </label>
                <Input
                  id={defaultMessages.city.id}
                  value={city}
                  onBlur={validateCity}
                  onChange={validateCity}
                  required
                  // placeholder={intl.formatMessage(defaultMessages.city)}
                  className="form-control"
                  error={
                    !validCity &&
                    intl.formatMessage(defaultMessages.city) + ' ' + intl.formatMessage(defaultMessages.required)
                  }
                  type={InputType.Text}
                  autoComplete="billing address-level2"
                />
              </div>
            </div>
          </div>
        </section>

        <div>
          <section className="ccDetails">
            <div className="box cc-wrap">
              {RDS.WhenLoading(null, () => (
                <div>
                  <Loader />
                </div>
              ))(rds)}

              <div className="form-wrap">
                <div className="form-group">
                  <label className="form-label">
                    <FormattedMessage id="cardHolderLabel" defaultMessage="Card Holder" />
                  </label>
                  <Input
                    id={defaultMessages.cc_holder.id}
                    value={cc_holder}
                    onBlur={validateCcholder}
                    onChange={validateCcholder}
                    required
                    // label="Postcode"
                    placeholder={intl.formatMessage(defaultMessages.cc_holder)}
                    className="form-control"
                    error={
                      !validCcholder &&
                      intl.formatMessage(defaultMessages.cc_holder) + ' ' + intl.formatMessage(defaultMessages.required)
                    }
                    type={InputType.Text}
                    autoComplete="cc-name"
                  />
                </div>
                <div className="form-group cc-mod-custom">
                  <label className="form-label" />
                  <CreditCardInput
                    cardNumberInputRenderer={({ props }) => (
                      <>
                        <p className="form-label">
                          <FormattedMessage id="cardNumberLabel" defaultMessage="Card Number" />
                        </p>
                        <input {...props} name="cc-number" className={!validCcnumber && 'error-border'} />
                        <p className="error-msg">
                          {!validCcnumber && (
                            <FormattedMessage id="cardNumber" defaultMessage="Credit Card Number is invalid" />
                          )}
                        </p>
                      </>
                    )}
                    cardCVCInputRenderer={({ props }) => (
                      <div className="input-wrapper">
                        <p className="form-label">
                          <FormattedMessage id="cvvLabel" defaultMessage="Cvv Code" />
                        </p>
                        <input {...props} className={!validCvv && 'error-border'} />
                        <p className="error-msg">
                          {!validCvv &&
                            intl.formatMessage(defaultMessages.expDate) +
                              ' ' +
                              intl.formatMessage(defaultMessages.invalid)}
                        </p>
                      </div>
                    )}
                    cardExpiryInputRenderer={({ props }) => (
                      <div className="input-wrapper">
                        <p className="form-label">
                          <FormattedMessage id="expDate" defaultMessage="Expiry Date" />
                        </p>
                        <input {...props} className={!validExpirationYear && 'error-border'} />
                        <p className="error-msg">
                          {!validExpirationYear &&
                            intl.formatMessage(defaultMessages.expDate) +
                              ' ' +
                              intl.formatMessage(defaultMessages.invalid)}
                        </p>
                      </div>
                    )}
                    fieldClassName="cc-mod-control"
                    dangerTextClassName="hidden"
                    cardNumberInputProps={{
                      autoComplete: 'cc-number',
                      value: cc_number,
                      onChange: (e) => {
                        setCc_number(e.target.value);
                        setValidCc_number(true);
                      },
                      onError: () => {
                        setValidCc_number(false);
                      },
                      onBlur: (ev) => {
                        handleFormEvents(
                          'cc-form',
                          'cc-card-number',
                          `${payment.fns.validateCardNumber(ev.target.value) ? 'valid' : 'invalid'}`,
                          ev
                        );
                      },
                      onClick: (ev) => {
                        handleFormEvents('cc-form', 'cc-card-number', `entry-started`, ev);
                      }
                    }}
                    cardExpiryInputProps={{
                      autoComplete: 'cc-exp',
                      onChange: handleCardExpiryInput,
                      onError: () => {
                        setValidExpirationYear(false);
                        setValidExpirationMonths(false);
                      },
                      onBlur: (ev) => {
                        handleFormEvents(
                          'cc-form',
                          'cc-expiry-input',
                          `${isExpiryInvalid(ev.target.value.split(' / ').join('/')) ? 'invalid' : 'valid'}`,
                          ev
                        );
                      },
                      onClick: (ev) => {
                        handleFormEvents('cc-form', 'cc-expiry-input', `entry-started`, ev);
                      }
                    }}
                    cardCVCInputProps={{
                      value: cvv,
                      autoComplete: 'cc-cvv',
                      onChange: (e) => {
                        setCvv(e.target.value);
                        setValidCvv(true);
                      },
                      onError: () => setValidCvv(false),
                      onBlur: (ev) => {
                        handleFormEvents(
                          'cc-form',
                          'cc-cvv',
                          `${payment.fns.validateCardCVC(ev.target.value) ? 'valid' : 'invalid'}`,
                          ev
                        );
                      },
                      onClick: (ev) => {
                        handleFormEvents('cc-form', 'cc-cvv', `entry-started`, ev);
                      }
                    }}
                  />
                </div>

                <div className="form-group" id="country">
                  <label className="form-label">
                    <FormattedMessage id="country" defaultMessage="Country" />
                  </label>
                  <Select
                    id={defaultMessages.country.id}
                    value={country}
                    onBlur={validateCountry}
                    onChange={validateCountry}
                    required
                    // firstOptionText="Select Country"
                    // label="Country"
                    placeholder={intl.formatMessage(defaultMessages.country)}
                    className="form-control"
                    error={
                      !validCountry &&
                      intl.formatMessage(defaultMessages.country) + ' ' + intl.formatMessage(defaultMessages.required)
                    }
                    type={SelectType.Country}
                  />
                </div>
                <div className="form-group">
                  <input type="checkbox" className="me" />
                  <label className="checkbox-wrap">
                    <p>
                      <FormattedMessage
                        id="regCheckbox1"
                        defaultMessage="By signing up for our website, you confirm your acceptance of the "
                      />
                      <a className="each" href="/content/en/terms-xracademy/?country=xx&service=xx" target="_blank">
                        <FormattedMessage id="terms-conditions" defaultMessage="Terms & Conditions" />
                      </a>
                      &nbsp;,&nbsp;&nbsp;
                      <a className="each" href="/content/en/privacy-ambglobal/?country=xx&service=xx" target="_blank">
                        <FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy" />
                      </a>
                      &nbsp;,&nbsp;&nbsp;
                      <FormattedMessage
                        id="regCheckbox2"
                        defaultMessage="and voluntarily opt into our subscription service, ensuring you receive the full benefits of our platform"
                      />
                      .
                    </p>
                  </label>
                </div>

                <button
                  id="user-details-submit-button"
                  type="submit"
                  className="btn dcbp btn-red"
                  disabled={
                    !email ||
                    !validEmail ||
                    !cc_holder ||
                    !validCcholder ||
                    !cc_number ||
                    !validCcnumber ||
                    !country ||
                    !validCountry ||
                    !cvv ||
                    !validCvv ||
                    !expirationMonths ||
                    !validExpirationMonths ||
                    !expirationYear ||
                    !validExpirationYear
                  }
                >
                  <FormattedMessage id="startTrial" defaultMessage="start trial" />
                </button>
              </div>
              {RDS.WhenFailure(null, (err: SubmitUserDetailsFailure) => {
                console.log('the err', err);
                tracker.recedeInFlow('Flow', 'payment-submission-failed');
                return (
                  <div className="errorMsg">
                    <FormattedMessage id={err.errorType} />
                    <br />
                    {err.errorType == 'AlreadySubscribed' && (
                      <a target="_blank" href={err.productUrl}>
                        <FormattedMessage id="goToProductLabel" defaultMessage="Go to Product" />
                      </a>
                    )}
                  </div>
                );
              })(rds)}
            </div>
          </section>
        </div>
      </form>
    </div>
  );
};

export default injectIntl(UserDetailsEntryStep);
