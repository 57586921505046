import React, { Suspense } from "react";

import store from "../__doNotModify/store";
import { Provider } from "react-redux";
import { TranslationProvider } from "../localization";
import { RootProvider } from "./RootContext";
import Loader from "../components/Loader";

const ProvidersWrapper = ({ children}) => {
 
  return(<Provider store={store}>
    <Suspense fallback={<Loader />}>
      <RootProvider>
        <TranslationProvider>
          {children}
        </TranslationProvider>
      </RootProvider>
    </Suspense>
  </Provider>
)};

export default ProvidersWrapper;